import "./site-header.scss";

import cn from "clsx";
import FocusTrap from "focus-trap-react";
import { CSSProperties, useCallback, useEffect, useRef, useState } from "react";
import { EditAttributes, SiteHeaderPosition } from "design-system/types/types";
import { SessionProps } from "frontend/types";
import {
  GlobalMenu,
  GlobalMenuProps,
} from "design-system/components/universal/global-menu/global-menu";
import { SiteHeaderTheme } from "design-system/utils/theme";
import { Link } from "design-system/components/primitives/link/link";
import { Icon } from "design-system/components/icons/icon";
import {
  LocalNavigation,
  LocalNavigationProps,
} from "design-system/components/universal/local-navigation/local-navigation";
import {
  SearchBoxDropdown,
  SearchBoxDropdownProps,
} from "design-system/components/search/search-dropdown/search-dropdown";
import {
  SiteHeaderAlert,
  SiteHeaderAlertProps,
} from "design-system/components/universal/site-header/site-header-alert";
import {
  SiteHeaderBanner,
  SiteHeaderBannerProps,
} from "design-system/components/universal/site-header/site-header-banner";
import { Logo } from "design-system/components/primitives/logo/logo";
import { useResizeObserver } from "design-system/hooks/use-resize-observer";

export interface SiteHeaderCta {
  link?: string;
  text?: string;
  hideIcon?: boolean;
}

export interface SiteHeaderProps {
  items?: LocalNavigationProps["items"];
  isHomepage?: boolean;
  isWorkingKnowledge?: boolean;
  globalMenu?: GlobalMenuProps;
  searchLink?: string;
  hasSearchOverlay?: boolean;
  search?: SearchBoxDropdownProps;
  logoLink?: string;
  theme?: SiteHeaderTheme;
  position?: SiteHeaderPosition;
  sectionTitle?: LocalNavigationProps["sectionTitle"];
  headerAlert?: SiteHeaderAlertProps;
  headerBanner?: SiteHeaderBannerProps;
  cta?: SiteHeaderCta;
  moreItemsTitle?: string;
  workingKnowledgeTagline?: string;
  workingKnowledgeTitleLink?: string;
  isMinimalHeader?: boolean;
  session?: SessionProps;
  siteType?: string;
  enableLogin?: boolean;
  enableLogout?: boolean;

  editAttributes?: {
    title?: EditAttributes;
    moreItemsTitle?: EditAttributes;
  };
}

/**
 * - **`id: UC-004-001-00`**
 * - **`data-region: uc__site-header__sitewide`**
 */
export function SiteHeader({
  items,
  theme,
  isHomepage,
  isWorkingKnowledge,
  sectionTitle,
  headerAlert,
  headerBanner,
  globalMenu,
  cta,
  logoLink,
  position = "sticky",
  searchLink,
  hasSearchOverlay = false,
  search,
  moreItemsTitle,
  workingKnowledgeTagline,
  workingKnowledgeTitleLink,
  editAttributes,
  isMinimalHeader = false,
  session,
  siteType,
  enableLogin = false,
  enableLogout = false,
}: SiteHeaderProps) {
  if (!Array.isArray(items)) items = [];

  const ref = useRef<HTMLElement>(null);

  const sectionTitleRef = useRef<HTMLDivElement>(null);
  const buttonsRef = useRef<HTMLDivElement>(null);

  const [sticky, setSticky] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { width: windowWidth } = useResizeObserver({
    current: typeof document !== "undefined" ? document.body : null,
  });

  const isSticky = useCallback(() => {
    const headerHeight = isWorkingKnowledge ? 160 : 100;
    const isDesktop = windowWidth >= 1024;
    setSticky(window.scrollY >= headerHeight && isDesktop);
  }, [isWorkingKnowledge, windowWidth]);

  useEffect(() => {
    window.addEventListener("load", isSticky);
    return () => window.removeEventListener("load", isSticky);
  }, [isSticky]);

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, [isSticky]);

  const bannerRef = useRef<HTMLDivElement>(null);

  const { height: bannerHeight } = useResizeObserver(bannerRef);

  const hasAlert = headerAlert !== undefined;

  const headerClass = cn(
    "hbs-site-header noindex",
    sticky && position === "sticky" && "hbs-site-header--sticky",
    position === "relative" && "hbs-site-header--relative",
    isMenuOpen && "hbs-site-header--menu-is-open",
    hasAlert && "hbs-site-header--header-alert",
    isWorkingKnowledge && "hbs-wk-site-header",
    isMinimalHeader && "hbs-site-header--minimal",
    isHomepage && "hbs-site-header--homepage",
  );

  const headerTheme =
    isMenuOpen || (sticky && position === "sticky") ? "dark" : theme;

  const siteTypeOption = siteType === "internal" ? "internal" : "external";

  let dataRegion = "uc__site-header" + isHomepage ? "__homepage" : "__sitewide";
  if (hasAlert) dataRegion += "__alert-banner";

  function toggleMenu() {
    setIsMenuOpen(!isMenuOpen);
  }

  function handleDeactivate() {
    setIsMenuOpen(false);
  }

  function closeMenuOnSearchOverlayOpen() {
    if (isMenuOpen) {
      handleDeactivate();
    }
  }

  const focusTrapProps: FocusTrap.Props = {
    active: isMenuOpen,
    focusTrapOptions: { onDeactivate: handleDeactivate },
  };

  let hasGlobalMenu = true;
  if (siteTypeOption === "internal") hasGlobalMenu = false;
  else if (!globalMenu?.items || globalMenu.items.length === 0) {
    hasGlobalMenu = false;
  }

  return (
    <>
      <Link
        href="#skipto-main"
        className="hbs-global-visually-hidden skipto noindex"
        target="_self"
      >
        Skip to Main Content
      </Link>
      <FocusTrap {...focusTrapProps}>
        <span data-region={dataRegion}>
          <header
            className={headerClass}
            data-theme={headerTheme}
            data-testid="site-header"
            ref={ref}
            role="banner"
            style={
              {
                "--banner-height": `${bannerHeight || 0}px`,
              } as CSSProperties
            }
          >
            <div ref={bannerRef}>
              {headerAlert && (
                <SiteHeaderAlert
                  {...headerAlert}
                  parentTheme={headerTheme}
                  menuIsOpen={isMenuOpen}
                  hasBanner={Boolean(headerBanner)}
                />
              )}
              {headerBanner && <SiteHeaderBanner {...headerBanner} />}
            </div>

            <div className="hbs-site-header__wrapper">
              <div className="hbs-site-header__logo-wrapper">
                <div className="hbs-site-header__logo">
                  <Link href={logoLink}>
                    {siteTypeOption === "external" && (
                      <Logo
                        title="Harvard Business School Logo"
                        siteType={siteTypeOption}
                      />
                    )}
                    {siteTypeOption === "internal" && (
                      <Logo title="myHBS Logo" siteType={siteTypeOption} />
                    )}
                  </Link>
                </div>
              </div>

              {!isHomepage && sectionTitle && (
                <div
                  className="hbs-site-header__section-title"
                  ref={sectionTitleRef}
                >
                  <Link
                    {...editAttributes?.title}
                    className="hbs-site-header__section-title-link"
                    href={sectionTitle.url}
                  >
                    {sectionTitle.title}
                  </Link>
                </div>
              )}

              {isWorkingKnowledge && (
                <div
                  className="hbs-site-header__section-title"
                  ref={sectionTitleRef}
                >
                  <Link
                    className="hbs-site-header__section-title-link"
                    href={workingKnowledgeTitleLink}
                  >
                    Working Knowledge
                  </Link>
                </div>
              )}

              {!isMenuOpen && !isWorkingKnowledge && (
                <div className="hbs-site-header__menu">
                  <LocalNavigation
                    moreItemsTitle={moreItemsTitle}
                    items={items}
                    isHomepage={isHomepage}
                    isSticky={sticky}
                    sectionTitle={sectionTitle}
                    editAttributes={editAttributes}
                    mobileCta={cta && { href: cta.link, children: cta.text }}
                  />
                </div>
              )}

              {isWorkingKnowledge && !isMenuOpen && (
                <div className="hbs-site-header__wk-header">
                  <Link
                    className="hbs-site-header__wk-title-link"
                    href={workingKnowledgeTitleLink}
                  >
                    <h1 className="hbs-site-header__wk-title">
                      Working Knowledge
                    </h1>
                  </Link>
                  <h6 className="hbs-site-header__wk-subtitle">
                    <em>{workingKnowledgeTagline}</em>
                  </h6>
                </div>
              )}

              <div className="hbs-site-header__buttons" ref={buttonsRef}>
                {session && (
                  <p>
                    {!session.user?.loggedIn &&
                      session?.loginUrl &&
                      enableLogin && (
                        <Link
                          href={session.loginUrl}
                          className="hbs-site-header__button hbs-site-header__cta"
                        >
                          Sign In
                        </Link>
                      )}
                  </p>
                )}
                {session && (
                  <p>
                    {session.user?.loggedIn &&
                      session.logoutUrl &&
                      enableLogout && (
                        <Link
                          href={session.logoutUrl}
                          className="hbs-site-header__button hbs-site-header__cta"
                        >
                          Logout
                        </Link>
                      )}
                  </p>
                )}
                {cta && (
                  <Link
                    href={cta.link}
                    className="hbs-site-header__button hbs-site-header__cta"
                  >
                    {cta.text}
                  </Link>
                )}
                {hasSearchOverlay ? (
                  <SearchBoxDropdown
                    {...search}
                    searchLink={searchLink}
                    dropdownItems={search?.dropdownItems}
                    isOverlay
                    onOpenCallback={closeMenuOnSearchOverlayOpen}
                  />
                ) : (
                  searchLink && (
                    <Link
                      className="hbs-site-header__button"
                      href={searchLink}
                      aria-label="Search"
                    >
                      <Icon
                        name="search"
                        className="hbs-site-header__button--search"
                      />
                    </Link>
                  )
                )}

                {hasGlobalMenu && (
                  <button
                    className="hbs-site-header__button"
                    onClick={toggleMenu}
                    data-testid="global-menu-trigger"
                    aria-label="Quick Links Menu"
                    aria-expanded={isMenuOpen}
                  >
                    {isMenuOpen ? (
                      <Icon
                        name="close"
                        className="hbs-site-header__button--menu"
                      />
                    ) : (
                      <Icon
                        name="menu"
                        className="hbs-site-header__button--menu"
                      />
                    )}
                  </button>
                )}
              </div>
            </div>

            {isWorkingKnowledge && !isMenuOpen && (
              <div className="hbs-site-header__menu">
                <LocalNavigation
                  moreItemsTitle={moreItemsTitle}
                  items={items}
                  isWorkingKnowledge={isWorkingKnowledge}
                  isSticky={sticky}
                  mobileCta={cta && { href: cta.link, children: cta.text }}
                  editAttributes={editAttributes}
                  buttonsRef={buttonsRef}
                  sectionTitleRef={sectionTitleRef}
                />
              </div>
            )}
          </header>

          {globalMenu && (
            <GlobalMenu
              {...globalMenu}
              open={isMenuOpen}
              numBanners={
                headerAlert && headerBanner
                  ? 2
                  : headerAlert || headerBanner
                    ? 1
                    : undefined
              }
            />
          )}
        </span>
      </FocusTrap>
    </>
  );
}
